.viewsocial-left-section {
    display: flex;
    width: 100%;
    margin-right: 10px;
    /* height: 100%; */
    min-width: 500px;
    flex-direction: column;
    justify-content: space-between
}

.left-parapraph {
    line-height: 1;
    margin-top: 12px;
    padding-left: 25px;
    border-left: 5px solid var(--inputBorderColor);
    max-width: 600px;
}

.text-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icons {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.instagram-icon {
    height: 35px;
    width: 35px;
}

.facebook-icon {
    height: 35px;
    width: 35px;
}

.left-end-button {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.right-image {
    padding: 12px;
    width: 350px;
}

.view-post-main-text {
    display: flex;
    justify-content: center;
}
.view-post-main {
    display: flex;
    justify-content: center;
    min-height: 450px;
    align-items: center;
}


.view-post-description {
    font-family: var(--FontFamily_1);
    text-align: left;
    font-weight: 500;
    color: rgb(23, 58, 64);
    font-size: 16px;
}