.SimplyAddressCard-inner-padding {
    padding: 10px 15px 10px 30px;
    min-height: 140px;
}

.Simplybutton {
    font-weight: 400;
    font-size: 14px;
    padding: 8px 25px;
    border-radius: 5px;
    text-align: center;
    text-transform: none;
    background-color: var(--whiteColor) !important;
    font-family: var(--FontFamily_1);
}

.selectbutton {
    font-weight: 400;
    font-size: 14px;
    padding: 8px 10px;
    border-radius: 5px;
    text-align: center;
    text-transform: none;
    background-color: var(--whiteColor) !important;
    font-family: var(--FontFamily_1);
}