.MuiTypography-h6 {
    font-family: var(--FontFamily_2) !important;
}

.commonmodel-title {
    color: var(--navLinkHoverColor);
    padding: 0;
    padding: 8px 0px;

    margin: 0px 24px;

}