.maincontainer-UploadAssets {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.maincontainer-UploadAssets-Document {
  display: flex;
  flex-wrap: wrap;
  margin: 10
}

.maincontainer-UploadAssets-flex {
  display: flex;
  align-items: center,
}

.container-UploadAssets {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 25vh;
  aspect-ratio: auto;
  overflow: auto;
  border: 2px dashed #007bff;
  border-radius: 5px;
  cursor: pointer;
  width: 22vw;
  margin-right: 10px;
  margin-bottom: 10px;
}

input[type='file'] {
  /* display: none; */
}

.container-UploadAssets.drag-over {
  border-color: var(--mainButtonColor);
  background-color: var(--inputBorderColor);
}

.delete-icon-UploadAssets {
  top: 5px;
  right: 5px;
}



.image-container {
  position: relative;
  display: inline-block;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  transition: filter 0.3s ease;
}

.button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .image {
  filter: blur(5px);
}

.image-container:hover .button-overlay {
  opacity: 1;
}





.delete-icon-UploadAssets {
  position: absolute;
  top: 5px;
  right: 5px;
  color: var(--redColor);
  cursor: pointer;
}

.delete-icon-UploadAssets:hover {
  font-size: 25px;
}