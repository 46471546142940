.custom-checkbox {
    position: relative;
    display: flex;
    align-items: center;
}

.custom-checkbox input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: -999999px;
}

.custom-checkbox input[type="checkbox"]+label {
    position: relative;
    padding-left: 30px;
    line-height: 20px;
    cursor: pointer;
}

.custom-checkbox input[type="checkbox"]+label:after,
.custom-checkbox input[type="checkbox"]+label:before {
    content: "";
    position: absolute;
    top: 2;
    left: 0;
    width: 18px;
    height: 18px;
    border: 2px solid var(--grayColor);
    border-radius: 4px;
}

.custom-checkbox input[type="checkbox"]+label:after {
    background-image: url(../../../assets/images/check-blue-ic.png);
    background-repeat: no-repeat;
    background-position: center center;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
    background-size: 18px;
    border-color: transparent;
}

.custom-checkbox input[type="checkbox"]:checked+label:after {
    transform: scale(1);
}
.singlecheckbox-input{
    width: 70px;
}

/* .custom-checkbox label {
    font-weight: 400;
    padding-left: 35px;
} */