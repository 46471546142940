.image-slider {
  position: relative;
  width: 100%;
  height: auto;
  /* margin-top: auto; */
}

.MuiSvgIcon-root {
  color: black;
}

.slick-next:before {
  content: var(--nextImage);
  width: 28px;
}

.slick-prev:before {
  content: var(--preImage);
  width: 28px;
  top: -20px;
}

.slick-prev {
  left: -30px;
}

.slick-prev:before,
.slick-next:before {
  /* font-size: 20px; */
  /* height: 20px; */
  /* width: 20px; */
  /* line-height: 1; */
  opacity: 1;
  /* color: white; */
  position: absolute;
  bottom: -22px;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  opacity: .75;
}


.next-icon,
.previous-icon {
  width: 50px;
  height: 50px;
  background-color: #ff4500 !important;
  /* Orange color for the buttons */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  position: absolute;
}

.next-icon {
  right: -25px;
}

.previous-icon {
  left: -25px;
}

/* Pulsating effect */
.pulsate .slick-slider .slick-prev {
  animation: zoomPulse 5s infinite;
}

.pulsate .slick-slider .slick-next {
  animation: zoomPulse 5s infinite;
}

/* @keyframes pulsate {
  0% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(1.1);
      opacity: 0.8;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
} */

@keyframes zoomPulse {
  0% {
    transform: scale(1);
    /* Normal size */
  }

  10% {
    transform: scale(1.2);
    /* Zoom in */
  }

  20% {
    transform: scale(1);
    /* Back to normal */
  }

  100% {
    transform: scale(1);
    /* Normal size until next cycle */
  }
}