.bulk-upload-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
}

.bulk-upload-header {
    text-align: center;
    padding: 20px;
}

.bulk-upload-header h1 {
    color: var(--maintablecolor);
}

.bulk-upload-instructions {
    padding: 20px;
    text-align: center;
}

.bulk-upload-instructions ul {
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
    list-style-type: disc;
    padding-left: 20px;
}

.bulk-upload-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
}

.bulk-upload-table th,
.bulk-upload-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.bulk-upload-table th {
    background-color: var(--maintablecolor);
    color: #fff;
}

.centered-text {
    text-align: center;
    vertical-align: middle;
}