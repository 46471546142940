/* General styles for the layout */
.screen-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  /* Prevents unwanted scrollbars */
}

/* Fixed position for the header */
.screen-layout>Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  /* Ensure the header stays above other elements */
  /* Adjust based on your header's height */
}

/* Main content area styles */
.main-content {
  flex: 1;
  padding-top: 90px;
  /* Adjust based on the height of the fixed header */
  /* padding-bottom: 60px;  */
  /* overflow-y: auto; Allows scrolling if the content overflows */
  box-sizing: border-box;
}

/* Ensure footer is at the bottom even if the content is short */
.screen-layout>Footer {
  width: 100%;
  height: 60px;
  /* Adjust based on your footer's height */
  position: fixed;
  bottom: 0;
}

/* * {
  font-family: "Barlow", sans-serif,
} */

body {
  overflow-y: scroll;
  background-color: white !important;
}

body::-webkit-scrollbar {
  display: none;
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  width: 100%;
  flex-direction: column;
  vertical-align: top;
}

.MuiButton-contained.Mui-disabled {
  color: var(--whiteColor) !important;
}

legend {
  float: none;
}

.fromcantrol-input {
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 0px !important;
}

#mui-component-select-MarketiongCalendarSelect {
  padding: 8px !important;
}

.MuiTab-wrapper {
  font-size: 18;
  font-weight: 900;
  color: var(--ButtonColor);
}

.pagetitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagetitle-left {
  margin-top: 5px;
}

.popup-hr {
  margin: 0.5rem 0;
}

.red-dot-container {
  display: flex;
}

.red-dot {
  width: 7px;
  height: 7px;
  background-color: var(--redColor);
  border-radius: 50%;
}


.my-facebook-button-class {
  background: #4267b2 !important;
  border-radius: 5px !important;
  height: 40px !important;
  border: 0px;
  text-align: center !important;
  width: 170px !important;
  font-size: 13px !important;
  padding: 0px !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--whiteColor);
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.my-instagram-button-class {
  background: #f09433 !important;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;

  border-radius: 5px !important;
  border: 0px;
  color: white !important;
  height: 40px !important;
  text-align: center !important;
  width: 170px !important;
  font-size: 13px !important;
  font-weight: 600;
  padding: 0px !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  /* font-size: inherit; */
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-pointer-none {
  cursor: none;
  pointer-events: none;
}

.MuiOutlinedInput-multiline {
  padding: 7px 0px !important;
}

.MuiButton-root {
  line-height: inherit !important;
  letter-spacing: normal !important;
}



/* 
.MuiButton-label {
  min-width: 80px;
} */




@media (max-width: 1500px) {}

@media (max-width: 1199px) {}

@media (max-width: 991px) {}

@media (max-width: 768px) {}


/* Html Builder styles overhide By Pinal */

.css-13ehocr-MuiStack-root {
  z-index: 0 !important;
}

.css-64o1gk-MuiPaper-root {
  z-index: 0 !important;
}

.css-1bqjmzd {
  z-index: 0 !important;
}

.css-1rups39-MuiPaper-root {
  z-index: 0 !important;
}

.css-1rups39 {
  z-index: 0 !important;
}


/* Location popup mediaquery for all popup */

@font-face {
  font-family: 'Myriad Pro';
  src: url('https://bllv.s3.us-west-1.amazonaws.com/Assets/fonts/MYRIADPRO-BOLD.woff') format('woff');
  font-weight: bold;
  font-style: bold;
}

/* @font-face {
  font-family: 'Myriad Pro';
  src: url('https://bllv.s3.us-west-1.amazonaws.com/Assets/fonts/MYRIADPRO-BOLDCOND.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('https://bllv.s3.us-west-1.amazonaws.com/Assets/fonts/MYRIADPRO-BOLDCONDIT.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('https://bllv.s3.us-west-1.amazonaws.com/Assets/fonts/MYRIADPRO-BOLDIT.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('https://bllv.s3.us-west-1.amazonaws.com/Assets/fonts/MYRIADPRO-COND.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('https://bllv.s3.us-west-1.amazonaws.com/Assets/fonts/MYRIADPRO-CONDIT.woff') format('woff');
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('https://bllv.s3.us-west-1.amazonaws.com/Assets/fonts/MYRIADPRO-Light.woff') format('woff');
} */

@font-face {
  font-family: 'Myriad Pro';
  src: url('https://bllv.s3.us-west-1.amazonaws.com/Assets/fonts/MYRIADPRO-REGULAR.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('https://bllv.s3.us-west-1.amazonaws.com/Assets/fonts/MYRIADPRO-SEMIBOLD.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('https://bllv.s3.us-west-1.amazonaws.com/Assets/fonts/MYRIADPRO-SEMIBOLDIT.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

/* GooglePlacesearch css */
.pac-container {
  z-index: 999999999 !important;
}