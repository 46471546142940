.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

/* .file-input {
    display: none;
  }
   */
.file-label {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.file-label:hover {
  background-color: #e0e0e0;
}



.form_field {
  width: 100%;
  justify-content: center;
}

.form_field .input-file-trigger {
  display: flex;
  border: 1px dashed #dddddd;
  cursor: pointer;
  border-radius: 0;
  width: 100%;
  max-width: 300px;
  height: 100px;
  background: #fff;
  font-size: 16px;
  font-weight: 400;
  /* color: rgba(100, 100, 100, 0.5); */
  align-items: center;
  justify-content: center;
}

#fileupload {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.form_field .input-file-trigger img {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}