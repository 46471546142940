.dot-progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  margin: 20px auto;
  position: relative;
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--blackColor);
  transition: background-color 0.3s ease;
  margin-bottom: 43px;
}

.dot.active {
  background-color: var(--mainColor);
}


.line {
  position: absolute;
  width: 7px;
  border-radius: 10px;
  background-color: var(--mainColor);
  transition: height 0.8s ease;
  top: 0px;
}