.form-main {
    /* box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px; */
    border: 2px solid #114d8b;
    padding: 10px;
    border-radius: 10px;
    border-top: 5px solid var(--maintablecolor);
    font-family: var(--FontFamily_2) !important;
}

.form-field-main {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0px;
    padding-bottom: 5px;
    justify-content: space-between;
}

.form-field-fullwidth {
    width: 100%;
    padding-bottom: 20px;
}

.form-field-hafewidth {
    width: 48%;
    padding-bottom: 20px;
}

.form-button {
    /* padding-top: 20px; */
    border-top: 1px solid var(--formcontrolBtnColor);
    padding-top: 10px;
    width: 100%;
    text-align: end;
    display: flex;
    justify-content: end;
    flex-direction: row;
}

.from-button-inner {
    padding-left: 20px;

}

#htmlediter-main {
    border: 1px solid var(--inputBorderColor);
    border-radius: 5px;
    margin-bottom: 20px;
}

.MuiTypography-body1 {
    font-family: var(--FontFamily_2) !important;
}

.MuiTypography-body2 {
    font-family: var(--FontFamily_2) !important;

}

.MuiMenuItem-root {
    font-family: var(--FontFamily_2) !important;

}


#fileupload {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: var(--mainColor);
    color: white;
    border: none;
    border-radius: 4px;
    text-align: center;
}

.custom-file-upload:hover {
    background-color: var(--mainColor);
}

.file-name {
    margin: 0px;
    padding-left: 10px;
    font-size: 14px;
    color: #333;
}