.left-round-arrow,
.right-round-arrow {
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
   opacity: 1;
   height: 24px;
   width: 24px;
}
.subtitlebutton2withPadding {
   margin-right: 50px;
   padding: 5px 6px 5px 18px;

}
.left-round-arrow {
   background-image: url('../../../assets/icons/Newicons/round-button-left.svg');
}

.right-round-arrow {
   background-image: url('../../../assets/icons/Newicons/round-button-right.svg');
}

.left-round-arrow:focus,
.left-round-arrow:hover {
   background-image: url('../../../assets/icons/Newicons/round-button-left-rollover.svg');
}

.right-round-arrow:focus,
.right-round-arrow:hover {
   background-image: url('../../../assets/icons/Newicons/round-button-right-rollover.svg');
}
.subtitlebutton2-margin {
   margin-right: 50px;
}
@media only screen and (max-width: 1330px) {
   .pagetitle-left-header {
      width: 50% !important;
   }
}

@media only screen and (max-width: 1330px) {
   .pagetitle-center-header {
      width: 30% !important;
      justify-content: start !important;
   }
   .viewartworkpopup-center-header{
      /* width: 30% !important; */
      justify-content: center !important;
   }

   .pagetitle-right-header {
      width: 28% !important;
   }
   .subtitlebutton2-margin {
      margin-right: 0px;
   }
}

.pageSubTitle-sticky {
   position: fixed;
   background-color: var(--whiteColor);
   top: 90px;
   width: 100%;
   z-index: 10;
   padding: 5px 0;
}